var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CContainer",
    { staticClass: "d-flex align-items-center" },
    [
      _c(
        "CRow",
        { staticClass: "w-100 mt-5 justify-content-center" },
        [
          _c("CCol", { attrs: { md: "6" } }, [
            _c(
              "div",
              { staticClass: "w-100" },
              [
                _c("div", { staticClass: "clearfix" }, [
                  _c("h1", { staticClass: "float-left display-3 mr-4" }, [
                    _vm._v("403"),
                  ]),
                  _c("h4", { staticClass: "pt-3" }, [
                    _vm._v("HTTP 403 Forbidden"),
                  ]),
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v(
                      " Access denied. You do not have the permission to access this page! "
                    ),
                  ]),
                ]),
                _c(
                  "CButton",
                  { attrs: { color: "dark", to: this.prevRoute } },
                  [
                    _c("CIcon", {
                      staticClass: "align-bottom",
                      attrs: { name: "cil-arrow-thick-left" },
                    }),
                    _vm._v(" Back "),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }